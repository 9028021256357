import axios from "axios";
import data from "../servers/public.js";
import { Notification, MessageBox, Message, Loading } from "element-ui";
import tansParams from "./getparams.js";
import errorCode from "@/utils/errorCode";
import { concat } from "lodash";
// console.log(axios);
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// let port = 9103;
const instance = axios.create({
  baseURL: data.baseURL,
  timeout: 10000, // 请求超时时间（毫秒）
  //   headers: {
  //     // 设置请求头，例如 token 等
  //     Authorization: "Bearer " + localStorage.getItem("token"),
  //   },
});

// 请求拦截器，在发送请求之前做一些处理，例如设置请求头、请求参数等
instance.interceptors.request.use(
  (config) => {
    console.log(config);
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;
    // 处理请求头，例如加入 token 等
    // const token = localStorage.getItem("token");
    // if (token) {
    //   config.headers["Authorization"] = "Bearer " + token;
    // }

    // 处理请求参数，例如添加时间戳等
    // get请求映射params参数
    if (config.method === "get" && config.params) {
      let url = config.url + "?" + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
      console.log(url);
    }

    if (
      !isRepeatSubmit &&
      (config.method === "post" || config.method === "put")
    ) {
      const requestObj = {
        url: config.url,
        data:
          typeof config.data === "object"
            ? JSON.stringify(config.data)
            : config.data,
        time: new Date().getTime(),
      };
      // const sessionObj = cache.session.getJSON("sessionObj");
      // if (
      //   sessionObj === undefined ||
      //   sessionObj === null ||
      //   sessionObj === ""
      // ) {
      //   cache.session.setJSON("sessionObj", requestObj);
      // } else {
      //   const s_url = sessionObj.url; // 请求地址
      //   const s_data = sessionObj.data; // 请求数据
      //   const s_time = sessionObj.time; // 请求时间
      //   const interval = 1000; // 间隔时间(ms)，小于此时间视为重复提交
      //   if (
      //     s_data === requestObj.data &&
      //     requestObj.time - s_time < interval &&
      //     s_url === requestObj.url
      //   ) {
      //     const message = "数据正在处理，请勿重复提交";
      //     console.warn(`[${s_url}]: ` + message);
      //     return Promise.reject(new Error(message));
      //   } else {
      //     cache.session.setJSON("sessionObj", requestObj);
      //   }
      // }
    }
    // config.baseURL += ":" + port;
    return config;
  },
  (error) => {
    // 处理请求错误
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

// 响应拦截器，在收到响应之后做一些处理，例如处理错误码、错误信息等
instance.interceptors.response.use(
  (response) => {
    // 处理响应数据
    // 未设置状态码则默认成功状态
    const code = response.data.code || 200;
    // 获取错误信息
    // debugger;
    // const msg = response.data.message;
    const msg = errorCode[code] || response.data.msg || errorCode["default"];
    // 二进制数据则直接返回
    if (
      response.request.responseType === "blob" ||
      response.request.responseType === "arraybuffer"
    ) {
      return response.data;
    }
    if (code === 500) {
      // Message({ message: msg, type: "error" });
      // return Promise.reject(new Error(msg));
      // alert("啊啊啊");
    } else if (code === 601) {
      // Message({ message: msg, type: "warning" });
      // return Promise.reject("error");
    } else if (code !== 200) {
      // Notification.error({ title: msg });
      // return Promise.reject("error");
    } else {
      return response.data;
    }
    // return response;
  },
  (error) => {
    // 处理响应错误
    console.error("Response error:", error);

    if (error.response) {
      // 处理 HTTP 响应错误码
      console.error("Response status:", error.response.status);
      console.error("Response data:", error.response.data);

      // 处理 HTTP 401 错误（未授权）
      if (error.response.status === 401) {
        console.error("Unauthorized, redirect to login page.");
        // TODO: 跳转到登录页
      }
    } else if (error.request) {
      // 处理 HTTP 请求错误
      console.error("No response received:", error.request);
    } else {
      // 处理其他错误
      console.error("Error:", error.message);
    }

    return Promise.reject(error);
  }
);

function updateConstructOrder(data) {
  // port = ports;
  // console.log(port, ports);
  return instance.get(`/order/contract/${data}`);
}
function customerSign(data) {
  // port = ports;
  // console.log(port, ports);
  return instance.post(`/order/customerSign`, data);
}
function getPayMessage(data) {
  // port = ports;
  // console.log(port, ports);
  return instance.post(`/order/getOrderPlace`, data);
}

function confirmPay(data) {
  // port = ports;
  // console.log(port, ports);
  return instance.post(`/order/placeAnOrder`, data);
}
function getIp() {
  return instance.get("/users/getip");
}
function upLoad(data) {
  // port = ports;
  // console.log(port, ports);
  const formData = new FormData();
  formData.append("file", data.file); // 将文件添加到 FormData 中
  formData.append("sign", data.sign);
  console.log(formData);
  // formData.append("name", data.name); // 如果还有其他参数，可以继续添加
  return instance.post(`/file/upLoad`, formData, {
    headers: {
      "Content-Type": "multipart/form-data", // 设置请求头为 multipart/form-data
    },
  });
}

export default {
  updateConstructOrder,
  customerSign,
  upLoad,
  confirmPay,
  getIp,
  getPayMessage,
};
