<template>
  <div class="success-page">
    <h1 class="success-title">签订成功！</h1>
    <img src="../../../public/images/logo.png" alt="" />
    <p class="success-description">恭喜您，操作已成功完成。</p>
    <button class="btn" @click="toPay" v-if="flag">跳转支付</button>
    <!-- <router-link to="/" class="back-home-link">返回首页</router-link> -->
  </div>
</template>

<script>
export default {
  name: "SuccessPage",
  data() {
    return {
      payObj: {
        orderId: "",
        paymoney: "",
        type: "",
        phoneType: "",
        ip: "",
      },
      flag: null,
    };
  },
  created() {
    console.log(this.$route.params, 222);
    this.payObj.orderId = this.$route.params.orderId;
    this.payObj.paymoney = this.$route.params.paymoney;
    this.flag = this.$route.params.flag;
  },
  methods: {
    toPay() {
      this.$router.push({
        name: "pay",
        params: {
          orderId: this.payObj.orderId,
          paymoney: this.payObj.paymoney,
        },
      });
    },
  },
};
</script>

<style scoped>
.success-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
}
img {
  position: absolute;
  top: 34%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.16rem;
  color: #999;
  width: 20%;
  height: 10%;
  /* transform: rotate(90deg); */
  /* opacity: 0.5; */
}

.success-title {
  font-size: 5px;
  font-weight: bold;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 3px;
}

.success-description {
  font-size: 0.24rem;
  text-align: center;
  color: #555;
  margin-bottom: 5px;
}
.btn {
  width: 20%;
  height: 4%;
  background-color: #ff0000;
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 0.5rem;
  margin-bottom: 10px;
}

.back-home-link {
  font-size: 0.18rem;
  text-align: center;
  color: #fff;
  background-color: #2c3e50;
  padding: 0.1rem 0.2rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.back-home-link:hover {
  background-color: #34495e;
}
</style>
