let isOnline = true; // 开发的时候 设置这里为false  ， 上线设置为true

// let baseURL = "https://121.199.36.145"; // yang 线上
let baseURL = "http://192.168.1.100"; // yang 线下

let networkResource = "http://192.168.1.100";
if (isOnline) {
  baseURL = "https://www.korbanger.com"; // 后端api地址 正式
}
let data = {
  isOnline,
  baseURL,
  networkResource,
};
export default data;
