import Vue from "vue";
import VueRouter from "vue-router";
import Product from "@/views/ProductView.vue";
import Contract from "@/views/contract/SignContract.vue";
import Signature from "@/views/customer/signAture.vue";
import Success from "@/views/contract/SuccessTip.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/product",
    name: "product",
    component: Product,
  },
  {
    path: "/contract",
    name: "contract",
    component: Contract,
    children: [],
  },
  {
    path: "/signature",
    name: "signature",
    component: Signature,
  },
  {
    path: "/success",
    name: "success",
    component: Success,
  },
  {
    path: "/life",
    name: "life",
    component: () => import(/* webpackChunkName: "about" */ "../views/life"),
  },
  {
    // path: "/pay/:orderId/:paymoney",
    path: "/pay",
    name: "pay",
    component: () => import(/* webpackChunkName: "about" */ "../views/pay"),
  },
  {
    // path: "/pay/:orderId/:paymoney",
    path: "/callback",
    name: "callback",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/callback"),
  },
  {
    // path: "/pay/:orderId/:paymoney",
    path: "/404",
    name: "404",
    component: () => import(/* webpackChunkName: "about" */ "../views/notfont"),
  },
];

const router = new VueRouter({
  // mode: "hash",

  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  // 判断是否从 `/signature` 页面跳转到 `/pay` 页面
  if (to.name === "pay" && from.name !== "success" && to.path !== "/pay") {
    // 从非 `/signature` 页面跳转到 `/pay` 页面，重定向到其他页面或首页
    next("/404");
  } else {
    // 允许跳转到目标页面
    next();
  }
});
export default router;
