<template>
  <div class="useragreement">
    <h2>用户协议</h2>
    <div class="usercontent">
      <p>网站用户协议</p>
      <p>
        欢迎使用本网站（以下简称“本站”）。在使用本站之前，请您仔细阅读并同意遵守本网站用户协议（以下简称“本协议”）。
      </p>
      <p>一、服务条款</p>
      <p>1.1 本站提供的服务包括但不限于网站浏览、信息查询、在线交流等。</p>
      <p>
        1.2
        用户在使用本站时，必须遵守国家法律法规、社会道德和本协议的规定，不得利用本站从事任何违法犯罪活动。
      </p>
      <p>
        1.3
        用户在使用本站时，应注意保护个人信息和隐私安全。本站将严格遵守相关法律法规，保护用户的个人信息不被泄露、篡改或丢失。
      </p>
      <p>
        1.4
        本站有权根据实际情况调整、变更、暂停或终止本站的服务，不承担因此产生的任何责任。
      </p>
      <p>二、用户行为</p>
      <p>
        2.1 用户在使用本站时，不得从事以下行为：
        （1）发布违反国家法律法规、社会道德和本协议规定的任何信息；
        （2）利用本站从事任何非法活动； （3）侵犯本站或他人的知识产权；
        （4）利用本站传播病毒、木马或其他恶意程序；
      </p>
      （5）其他违反法律法规、社会道德和本协议规定的行为。
      <p>
        2.2
        用户应当对自己发布的信息和行为承担全部责任，如因此给本站或他人造成损失的，应当承担相应的法律责任。
      </p>
      <p>三、隐私政策</p>
      <p>3.1 本站将严格保守用户的个人信息和隐私，不会向第三方透露。</p>
      <p>
        3.2 用户在注册或使用本站时，需提供一些必要的个人信息，本站将严格保密。
      </p>
      <p>
        3.3 本站将采取各种安全措施，保障用户的个人信息和隐私安全。 四、版权声明
      </p>
      <p>
        4.1
        本站上的所有文章、图片、音频、视频等作品均受版权法保护，未经本站或版权人授权，不得转载、摘编或以其他方式使用。
      </p>
      <p>
        4.2
        用户发布的任何信息，均视为授权本站在全球范围内永久性、不可撤销地使用、复制、修改、发布、翻译和分发。
      </p>
      <p>五、免责声明</p>
      <p>
        5.1
        本站不对用户发布的任何信息负责，也不对因用户使用本站而造成的任何直接或间接损失负责。
      </p>
      <p>5.2 本站不对因不可抗力、黑客攻击、计算机病毒等造成的任何损失负责。</p>
      <p>六、服务的修改和终止</p>
      <p>
        6.1
        本站有权随时修改、更新本协议，并在本站公布。用户应不时关注本协议的变更情况。
      </p>
      <p>
        6.2
        如用户违反本协议的规定，本站有权立即终止向该用户提供服务，且无需承担任何责任。
      </p>
      <p>七、争议解决和法律适用</p>
      <p>7.1 本协议的订立、执行和解释均适用中华人民共和国法律。</p>
      <p>
        7.2
        本协议的任何争议，应当通过友好协商解决，协商不成的，任何一方均可向本站所在地人民法院提起诉讼。
      </p>
      <p>八、其他</p>
      <p>
        8.1 本协议构成用户与本站之间的全部协议，取代所有先前的口头或书面协议。
      </p>
      <p>
        8.2
        如本协议中的任何条款无论因何种原因被认定为无效或不可执行，其余条款仍应继续有效并且具有约束力。
      </p>
      <p>8.3 本站在法律允许的范围内拥有对本协议的解释权和修改权。</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.usercontent {
  text-align: left;
  padding: 0 100px;
  /* display: flex; */
  /* align-content: flex-start; */
}
</style>
